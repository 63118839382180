// src/App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; // Import Link
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import FeaturedBlog from "./components/FeaturedBlog"; // Commented out to hide the featured blog
import ContactForm from "./components/ContactForm"; // Add your contact form component
import Faq from "./components/Faq";
import BlogPost from "./components/BlogPost";
import BorderlinePersonalityDisorder from "./components/Services/BorderlinePersonalityDisorder";
import ChildrenTeenTherapy from "./components/Services/ChildrenTeenTherapy";
import Depression from "./components/Services/Depression";
import Grief from "./components/Services/Grief";
import Anxiety from "./components/Services/Anxiety";
import Trauma from "./components/Services/Trauma";
import SplashScreen from "./components/SplashScreen";
import "./App.css";
import AngerManagementTherapy from "./components/Services/AngerManagementTherapy";
import AffordableTherapy from "./components/Services/AffordableTherapy";
import Fees from "./components/Fees";





function HomePage() {
  const [isLoading, setIsLoading] = useState(true); // Start as loading
  const [isFading, setIsFading] = useState(false); // Controls fade out
  const [contentVisible, setContentVisible] = useState(false); // Controls content visibility

  useEffect(() => {
    // Check if the splash screen has been shown before
    const splashScreenShown = localStorage.getItem("splashScreenShown");

    if (!splashScreenShown) {
      // Show the splash screen only the first time
      localStorage.setItem("splashScreenShown", "true"); // Set flag to prevent showing again

      // Start splash screen
      const timer = setTimeout(() => {
        setIsFading(true); // Trigger the fade-out
      }, 3000); // Show the splash screen for 3 seconds

      const fadeOutTimer = setTimeout(() => {
        setIsLoading(false); // Stop loading (remove splash screen)
        setContentVisible(true); // Show the content
      }, 4000); // This should be slightly longer than the fade-out duration (1s)

      // Cleanup timers on component unmount
      return () => {
        clearTimeout(timer);
        clearTimeout(fadeOutTimer);
      };
    } else {
      // If the splash screen has already been shown, show content immediately
      setIsLoading(false); // Ensure splash screen is skipped
      setContentVisible(true); // Show the homepage content
    }
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <SplashScreen className={isFading ? "hide" : ""} />
      ) : (
        <div className={`main-content ${contentVisible ? "fade-in-page" : ""}`}>
          <MainSection />
          {/* Link to Featured Blog */}
          <Link to="/blog">Go to Blog</Link>
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/borderlinepersonalitydisorder" element={<BorderlinePersonalityDisorder />} />
        <Route path="/childrenteentherapy" element={<ChildrenTeenTherapy />} />
        <Route path="/depression" element={<Depression />} />
        <Route path="/grief" element={<Grief />} />
        <Route path="/anxiety" element={<Anxiety />} />
        <Route path="/trauma" element={<Trauma />} />
        {/* Changed this line to update the path */}
        <Route path="/blog" element={<FeaturedBlog />} /> 
        <Route path="/AngerManagementTherapy" element={<AngerManagementTherapy />} />
        <Route path="/AffordableTherapy" element={<AffordableTherapy />} />
        <Route path="/Fees" element={<Fees />} />

        


        {/* Add additional routes for services, FAQ, etc. */}
      </Routes>
      <footer>
        <p>© 2024 Aestus Health | <a href="https://www.google.com/maps/search/?api=1&query=3310+S+Service+Rd+Burlington,+ON+L7N+3M6" target="_blank" rel="noopener noreferrer">3310 S Service Rd Burlington, ON</a> | <a href="tel:2897972794">289-797-2794</a></p>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=100094541214954"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icon" src="/facebook-icon.png" alt="Facebook" />
          </a>

          <a
            href="https://www.instagram.com/aestushealth/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icon" src="/instagram-icon.png" alt="Instagram" />
          </a>
        </div>
      </footer>
    </Router>
  );
}

export default App;
