import React from "react";
import "./Fees.css";

export default function FeesSection() {
  return (
    <section className="fees-section">
      <div className="text-section">
        <h2 className="cursive-heading">Fees</h2>
        <p>
          My standard rate for individual sessions is $150. However, to make therapy more accessible, I have limited slots at $65 and lower. Finally, I offer free 30-minute initial consultation calls.
        </p>
        <div className="image-section-fees mobile-image">
          <img src="/Fees.jpg" alt="Fees" />
        </div>
        <h2 className="cursive-heading">Payment Methods</h2>
        <p>
          Currently, I am accepting payments through e-transfer and credit card.
        </p>
        <h2 className="cursive-heading">Cancellation Policy</h2>
        <p>
          I have a 48-hour cancellation policy. If you are unable to make it to an appointment please let me know at least 48 hours in advance to avoid being billed the full session fee. Cancellation fees can be waived if there is an emergency or there are circumstances outside of your control that cause you to be unable to make your appointment.
        </p>
      </div>
      <div className="image-section-fees desktop-image">
        <img src="/Fees.jpg" alt="Fees" />
      </div>
    </section>
  );
}
