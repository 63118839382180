import React from "react";
import "./AffordableTherapy.css";

export default function AffordableTherapy() {
  return (
    <section className="affordable-therapy-section">
      <div className="text-section">
        <h2 className="cursive-heading">Affordable Therapy</h2>
        <p>
          As someone who has been on the other side of the couch, I am sensitive to the fact that one’s financial wellbeing can affect one’s mental wellbeing. The cost of living has increased significantly and not everyone is able to afford the standard rates that therapists charge. This can put someone who is struggling with their mental health in a bind of having to choose between their financial wellbeing and mental wellbeing.
        </p>
        <p>
          At Aestus Health, I believe that therapy should be accessible to everyone. I recognize that the path to healing and emotional growth should not be hindered by financial limitations. For this reason, I offer slots at <strong>$65 or lower for individuals who need it</strong>. I believe everyone should have the opportunity to become the best versions of themselves regardless of their financial situation.
        </p>
        <div className="image-section-affordable mobile-image">
          <img src="/affordable-therapy.jpg" alt="Affordable Therapy" />
        </div>
        <p>
          If you would like to know more about my affordable therapy slots, you are welcome to email me at 
          <a href="mailto:ali@aestushealth.com"> ali@aestushealth.com</a> or 
          <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer"> book a free consultation call</a>.
        </p>
      </div>
      <div className="image-section-affordable desktop-image">
        <img src="/affordable-therapy.jpg" alt="Affordable Therapy" />
      </div>
    </section>
  );
}
