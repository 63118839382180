import React from 'react';

export default function SplashScreen({ className }) {
  return (
    <div className={`splash-screen ${className}`}>
      <img src="/AestusLogo.png" alt="Aestus Logo" className="splash-logo" />

      <h1 className="splash-text">Aestus Health</h1>
    </div>
  );
}