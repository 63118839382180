import React, { useState, useRef, useEffect } from "react";
import "./styles/Faq.css";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const answerRefs = useRef([]);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const formatAnswer = (answer) => {
    if (typeof answer === 'string') {
      return answer.split("\n").map((text, index) => (
        <React.Fragment key={index}>
          {text}
          <br />
        </React.Fragment>
      ));
    }
    return answer; // Return as is if it's not a string
  };

  useEffect(() => {
    document.title = "FAQ - Aestus Health"; // Set the tab name to "FAQ - Aestus Health"
  }, []);

  useEffect(() => {
    if (activeIndex !== null && answerRefs.current[activeIndex]) {
      const answerHeight = answerRefs.current[activeIndex].scrollHeight;
      answerRefs.current[activeIndex].style.height = `${answerHeight}px`;
    }

    answerRefs.current.forEach((ref, idx) => {
      if (idx !== activeIndex && ref) {
        ref.style.height = "0px";
      }
    });
  }, [activeIndex]);

  const faqs = [
    {
      question: "Do you offer free consultations?",
      answer: (
        <span>
          Yes, I offer free 30-minute consultation calls. If this is something you
          would be interested in, you can book one using our{" "}
          <a
            href="https://aestushealth.janeapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            booking page
          </a>{" "}
          or contacting me through ali@aestushealth.com.
        </span>
      ),
    },
    {
      question: "What happens if I have to cancel a session?",
      answer: "If you cannot make it to your appointment, please let me know 24 hours in advance to avoid a cancellation fee.",
    },
    {
      question: "How do you know if you need therapy?",
      answer: (
        <div>
          <p>
            <strong>Knowing when to seek therapy can be a personal and subjective decision, but there are some general signs that may indicate it’s time to consider professional help.</strong> Here are some questions you can ask yourself to determine if therapy could benefit you:
          </p>
          <p>
            <strong>Are your emotions interfering with your daily life?</strong> If you find that feelings of sadness, anxiety, anger, or other emotions are preventing you from engaging in daily activities or maintaining relationships, therapy can help you understand and manage these emotions effectively.
          </p>
          <p>
            <strong>Are you experiencing a significant life change or event?</strong> Major life transitions, such as a divorce, a new job, or the loss of a loved one, can be challenging to navigate on your own. Therapy can provide a safe space to process these changes and develop coping strategies.
          </p>
          <p>
            <strong>Do you feel overwhelmed or unable to cope with stress?</strong> If you find that your usual stress management techniques are no longer working, or you feel constantly overwhelmed, a therapist can help you explore new ways to cope with stress and improve your overall well-being.
          </p>
          <p>
            <strong>Are you engaging in harmful behaviors?</strong> If you find yourself relying on substances, self-harm, or other destructive behaviors to cope with difficult emotions, therapy can provide a safe and non-judgmental environment to address these behaviors and develop healthier coping mechanisms.
          </p>
          <p>
            <strong>Do you want to improve your relationships or communication skills?</strong> Therapy can be a valuable resource for individuals who want to enhance their interpersonal skills, understand their relationship patterns, and develop more fulfilling connections with others.
          </p>
          <p>
            <strong>Do you want to gain a better understanding of yourself?</strong> Therapy can help you explore your thoughts, emotions, and behaviors, leading to greater self-awareness and personal growth.
          </p>
        </div>
      ),
    },
    {
      question: "What exactly do you do in therapy?",
      answer: (
        <div>
          <p>
            As your therapist, my main goal is to provide a safe and supportive environment where we can work together to improve your well-being and address your concerns. To begin, we'll spend time getting to know each other during the intake and assessment phase. This allows me to gather important information about your background, current challenges, and aspirations for therapy.
          </p>
          <p>
            <strong>Throughout our sessions, you'll have the opportunity to share your thoughts, feelings, and experiences openly and without judgment.</strong> Together, we'll explore patterns in your thoughts and behaviors and identify the root causes of your challenges. My role is to listen empathetically, ask thought-provoking questions, and offer insights to help you gain a deeper understanding of yourself.
          </p>
          <p>
            <strong>In some cases, we may discuss and process past experiences or traumas that may be affecting your current well-being.</strong> This can help you gain a deeper understanding of yourself and work towards healing and personal growth. Throughout our time together, we'll set goals, track your progress, and adjust your treatment plan as needed. This ongoing collaboration ensures that your needs are being addressed and that you're moving towards a more fulfilling life.
          </p>
        </div>
      ),
    },
    {
      question: "Is therapy just venting?",
      answer: (
        <div>
          <p>
            Therapy is much more than just venting, although expressing your thoughts and emotions is certainly a part of the process. It involves learning and practicing new coping skills, processing past experiences, and identifying and changing unhelpful patterns of thinking and behaving. Ideally, therapy is a way to improve your overall mental and emotional well-being, equipping you with the tools needed to navigate life's challenges with greater resilience and understanding.
          </p>
          <p>
            That said, as a therapist, my goal before anything else is to make sure my patients feel heard and understood. In order for therapy to be effective, there needs to be a foundation of empathy and understanding.
          </p>
        </div>
      ),
    }
    ,
    {
      question: "How to start seeing a therapist?",
      answer: (
        <div>
          <p>
            Starting therapy can seem intimidating, but here are some simple steps to help you get started:
          </p>
          <p>
            <strong>Do some research:</strong> Look into different types of therapy and therapists in your area. Consider their specialties, experience, and reviews from previous clients.
          </p>
          <p>
            <strong>Check your insurance coverage:</strong> Find out if your insurance plan covers therapy sessions, and which therapists are in your network. This can help you narrow down your options and determine potential costs.
          </p>
          <p>
            <strong>Reach out to potential therapists:</strong> Contact therapists you're interested in working with and ask about their availability, fees, and approach to therapy. Many therapists offer a free initial consultation, which can help you get a feel for whether they're a good fit.
          </p>
          <p>
            <strong>Schedule an appointment:</strong> Once you've found a therapist you're comfortable with, schedule your first session. This is a great opportunity to discuss your concerns, goals, and expectations for therapy.
          </p>
          <p>
            You are welcome to <a href="https://aestushealth.janeapp.com" target="_blank" rel="noopener noreferrer">book a free consultation call with myself</a> as well to see if we would be a good fit working together.
          </p>
        </div>
      ),
    },
  ];

  return (
    <section className="faq-page fade-in-page">
      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleFaq(index)}
            >
              <div className="faq-question">
                {activeIndex === index ? "−" : "+"} {faq.question}
              </div>
              <div
                className="faq-answer"
                ref={(el) => (answerRefs.current[index] = el)}
                style={{
                  height:
                    activeIndex === index
                      ? `${answerRefs.current[index]?.scrollHeight}px`
                      : "0px",
                }}
              >
                <div className="faq-answer-content">
                  {formatAnswer(faq.answer)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="faq-image-section">
        <img src="/faq.jpg" alt="FAQ Image" />
      </div>
    </section>
  );
}
